import React, { FC } from "react";
export { Head } from "src/utils/head";

import { Layout } from "components/template/layout";

import { ExperienceContainer } from "./_expContainer";

const ExperiencePage: FC = () => {
  return (
    <Layout>
      <ExperienceContainer />
    </Layout>
  );
};

export default ExperiencePage;
