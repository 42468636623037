import React, { FC, PropsWithChildren } from "react";
import styled, { css } from "styled-components";

interface TitleProps {
  fontSize: string;
  mobilefontsize?: string;
  type: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  fontFamily?: "Post Grotesk" | "Post Grotesk Thin" | "Post Grotesk Bold";
  fontWeight?: string;
  align?: string;
  mb?: number;
  mr?: number;
  mt?: number;
  color?: string;
}

export const TitleStyle = css<TitleProps>`
  font-family: ${({ fontFamily }) => fontFamily ?? "Post Grotesk"};
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight ?? "normal"}px;
  margin-bottom: ${({ mb }) => mb ?? 0}px;
  margin-right: ${({ mr }) => mr ?? 0}px;
  margin-top: ${({ mt }) => mt ?? 0}px;
  text-align: ${({ align }) => align ?? "left"};
  color: ${({ color }) => color ?? "#000"};

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    font-size: ${({ mobilefontsize, fontSize }) =>
      mobilefontsize ?? parseInt(fontSize) / 1.5}px;
  }

  @media (max-width: ${({ theme }) => theme.device.portraitTablet}px) {
    font-size: ${({ mobilefontsize, fontSize }) =>
      mobilefontsize ?? parseInt(fontSize) / 3}px;
  }
`;

export const Title: FC<PropsWithChildren<TitleProps>> = styled(
  ({ type, ...props }: PropsWithChildren<TitleProps>) =>
    React.createElement(type, props)
)`
  ${TitleStyle}
`;
